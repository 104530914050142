.header-bg-dark-green {
  background-color: #3FA8E1 !important;
}
.navbar-bg-dark-green {
  background-color: #ffff !important;
  .nav .nav-link {
    color: #000 !important;
    .nav-icon {
      color: #000 !important;
    }
    &.active {
      background: #00517D !important;
      color: white !important;
      .nav-icon {
        color: white !important;
      }
    }
  }
  .nav .nav-item:hover {
    background: #3FA8E1 !important;
    .nav-link {
      color: white !important;
      background: #3FA8E1 !important;
    }
  }
}

