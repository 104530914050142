// Here you can add other styles
.btn.btn-dark-green {
  background-color: #52c6ef;
  border-color: #3fa8e1;
  color: #fff;
}

.a-action {
  cursor: pointer;
  color: #3fa8e1 !important;
}

.a-action:hover {
  border-bottom: 1px solid;
}

.btn-hidden {
  background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.wrapper-icon-header-user {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customTrStyle {
  height: 30px !important;
}

.table th,
.table td {
  padding: 0.35rem !important;
}

.btn-info.override {
  background-color: #fff;
  border: 1px solid #c8ced3;
  color: #20a8d8;
}

.fixed-btn-form {
  position: fixed;
  right: 35px;
  bottom: 30px;
}

.cursor-pointer {
  cursor: pointer;
}
