.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacer * .75;
  .logo-container__typography {
    margin-bottom: 0.5rem;
    letter-spacing: $spacer * .07;
  }
  img {
    width: 100px;
    height: auto;
  }
}
